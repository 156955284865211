<template>
  <div>
    <div class="entrance-item">
      <div class="block-header">
        <h6 class="entrance-title">{{ entrance.entrance }} подъезд</h6>
        <v-menu offset-y class="edit-entrance" v-show="showMenuEntrance">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="dots-open"
              v-bind="attrs"
              v-on="on"
            >
              <img src="@/assets/images/dots.svg" alt="dots" />
            </v-btn>
          </template>
          <v-list>
            <v-list-item>
            <!-- <div class="edit" @click="toggleEditEntrance">Редактировать</div> -->
            <div class="delete" @click="toggleDeleteEntrance">Удалить</div>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <!-- <div class="total-debt">Общий долг: {{debtTotal}}</div>-->
      <hr style="height: 2px;color: #F0F0F3;border-width: 0px;background-color:#F0F0F3;margin-top: 30px" >
      <div class="total-debt"></div>
      <div class="floor-items">
        <div v-for="(floor, key) in aptMatrix" :key="key" class="floor-item" v-if="key">
          <EditFloor
          :floor="key"
          :block="block"
          :entrance="entrance"
          @delete-floor="deleteFloor"
          />
          <div class="flat-row">
            <div
              v-for="apt in floor"
              :key="apt.apartment_id"
              class="flat-num"
              :class="{
                'grey': apt.personal_account
              }"
              @click="clickOnApt(apt)"
              v-if="apt.number"
            >{{ apt.number }}</div>
            <AddFlat
              :block="block"
              :entrance="entrance"
              :floor="floor"
              @create="addFlat"
            />
          </div>
        </div>
      </div>
      <div class="create-block">
        <input v-if="isAddingFloor"
              v-model="floorName"
              ref="addFloorInput"
              class="addFloorInput"
              placeholder="№ этажа"
              @blur="stopAdding"
              @keyup.enter="hideAddFloor(block.id,entrance.entrance,floorName)"
        >
        <div v-if="isAddingFloor" class="create-block-btn" @click="hideAddFloor(block.id,entrance.entrance,floorName)">
          <img src="../../assets/images/Active.svg" alt="">
        </div>
        <div v-if="isAddingFloor" class="cancel-block-btn" @click="stopAdding">
          <img src="../../assets/images/cancel-btn.svg" alt="">
        </div>
      </div>
      <div v-if="!isAddingFloor" class="add-floor-btn" @click="addFloor">+Добавить этаж</div>
    </div>
    <div v-if="showDeleteEntrance" style="z-index: 10000; position: absolute">
      <DeletionConfirm
        title="Вы действительно хотите удалить"
        :description="`'${entrance.entrance}' подъезд из блока '${block.number}'?`"
        @close="toggleDeleteEntrance"
        @delete="deleteEntrance"
      />
    </div>
    <div v-if="showEditEntrance" style="z-index: 10000; position: absolute">
      <EditEntrance
      :aptMatrix = aptMatrix
      :entrance = entrance
      :block = block
      @deleteFloor = "deleteFloor"
      @close = "toggleEditEntrance"
      />
    </div>
  </div>
</template>
<script>
import { deleteEntranceApi } from '@/api/buildings';
import { deleteFloorApi } from "@/api/buildings";
import {addFloorApi} from "@/api/buildings";
import {addApartamentApi} from "@/api/buildings";

export default {
  components: {
    DeletionConfirm: () => import('@/components/modals/DeletionConfirm.vue'),
    EditEntrance: () => import('@/components/modals/EditEntrance.vue'),
    AddFlat: () => import('@/components/modals/AddFlat.vue'),
    EditFloor: () => import('@/components/modals/EditFloor.vue'),
  },
  props: {
    entrance: {
      type: Object,
      default: () => {},
    },
    block: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    showMenuEntrance: false,
    showEditEntrance: false,
    showDeleteEntrance: false,
    isAddingFloor: false,
    floorName: "",
    addingFlat:false,
    showEditFloor: false,
  }),
  computed: {
    aptMatrix() {
      const apts = this.entrance?.apartments?.reduce((acc, cur) => {
        let floor = acc;
        if (Array.isArray(floor[cur.floor])) {
          floor[cur.floor].push(cur);
        } else {
          floor[cur.floor] = [];
          floor[cur.floor].push(cur);
        }
        return floor;
      }, {});
      return apts;
    },
    debtTotal() {
      return this.entrance.apartments?.reduce((acc, cur) => {
        const debt = acc + (cur.owner?.debt || 0);
        return debt;
      }, 0);
    },
  },
  mounted() {
    this.$emit("hideSkeleton");
    this.$store.commit("DEL_LOADING", "APARTMENTS_LOADING");
  },
  methods: {
    closeMenuEntrance(e){
      if (!this.$el.contains(e.target)) {
        this.showMenuEntrance = false
      }
    },
    toggleEditEntrance() {
      this.showEditEntrance = !this.showEditEntrance;
      this.showMenuEntrance = false;
    },
    toggleDeleteEntrance() {
      this.showDeleteEntrance = !this.showDeleteEntrance;
      this.showMenuEntrance = false;
    },
    clickOnApt(apt) {
      if (apt.personal_account) {
        this.$emit('showResident', apt, this.block, this.entrance.entrance);
        console.log('showRes');
      } else {
        this.$emit('addResident', { ...apt, block: this.block, entrance: this.entrance.entrance });
        console.log('addResident');
      }
      console.log(apt.owner);
    },
    async deleteEntrance() {
      try {
        await deleteEntranceApi({
          block: this.block.id,
          number: this.entrance.entrance,
        });
        this.$emit('refreshChess');
        this.$store.commit('SHOW_SNACKBAR', 'deleteEntranceSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Подъезд успешно удалён');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'deleteEntranceErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при удалении подъезда');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
    async deleteFloor(block, entrance, floor) {
      try {
        await deleteFloorApi({
          block: block,
          entrance: entrance,
          floor: floor,
        });
        this.$emit('refreshChess');
        this.$store.commit('SHOW_SNACKBAR', 'deleteFloorSuccessSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Этаж успешно удалён');
        this.$store.commit('SET_SNACKBAR_COLOR', 'success');
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'deleteFloorErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при удалении этажа');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
    addFloor(){
      this.isAddingFloor = true;
      this.$nextTick(() => this.$refs.addFloorInput.focus());
    },
    stopAdding(){
      setTimeout(()=>{
        this.isAddingFloor = false;
        this.floorName = "";
      },200);
    },
    async hideAddFloor(block, entrance, floor) {
      try {
        let res = await addFloorApi({
          block: block,
          entrance: entrance,
          floor: floor
        });
        if (res.data.message === "Floor exist!") {
          this.stopAdding();
          this.$store.commit('SHOW_SNACKBAR', 'addFloorErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Такой этаж уже существует!');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        } else {
          this.stopAdding();
          this.$emit('refreshChess');
          this.$store.commit('SHOW_SNACKBAR', 'addFloorSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Этаж успешно добавлен');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        }
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'addFloorErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при добавлении этажа');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    },
    async addFlat(block, entrance, floor, newFlatName) {
      try {
        let res = await addApartamentApi({
          block: block,
          entrance: entrance,
          floor:floor,
          apartment: newFlatName,
        });
        if (res.data.message === "Apartment exist!") {
          this.$store.commit('SHOW_SNACKBAR', 'addApartmentErrorSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Такая квартира уже существует!');
          this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        } else {
          this.$emit('refreshChess');
          this.$store.commit('SHOW_SNACKBAR', 'addApartmentSuccessSnackbar');
          this.$store.commit('SET_SNACKBAR_TITLE', 'Квартира успешно добавлена');
          this.$store.commit('SET_SNACKBAR_COLOR', 'success');
        };
      } catch (err) {
        this.$store.commit('SHOW_SNACKBAR', 'addApartmentErrorSnackbar');
        this.$store.commit('SET_SNACKBAR_TITLE', 'Ошибка при добавлении квартиры');
        this.$store.commit('SET_SNACKBAR_COLOR', 'error');
        throw err;
      };
    }
  },
};
</script>

<style>
</style>
